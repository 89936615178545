// import Behance from "../socialmediaIcons/Behance";
import Instagaram from "../socialmediaIcons/Instagaram";
import LinkedIn from "../socialmediaIcons/LinkedIn";
// import Twitter from "../socialmediaIcons/Twitter";
import CSE from "../Logo/CSE";
import PromptPrivacy from '../Logo/PromptPrivacy'
// import ProgrammersForce from '../Logo/ProgrammersForce'
// import Katia from '../Logo/Katia'
import VerPower from '../Logo/VerPower'
import Auralian from '../Logo/Aurelian'
import Sparkai from '../Logo/Sparkai'
import Ethera from '../Logo/Ethera'
import Youtube20 from '../Logo/Youtube20'
import profilePic from './assets/Images/Shahid.png'
const socialLinks = [
  {
    id: 1,
    href: "https://www.linkedin.com/in/shahid-muneer-219b4220a/",
    Component: LinkedIn,
    alt: "LinkedIn",
  },
  {
    id: 2,
    href: "https://www.instagram.com/shahid_muneer_awan/",
    Component: Instagaram,
    alt: "Instagram",
  },
  // {
  //   id: 3,
  //   href: "link-to-behance-profile",
  //   Component: Behance,
  //   alt: "Behance",
  // },
  // {
  //   id: 4,
  //   href: "link-to-twitter-profile",
  //   Component: Twitter,
  //   alt: "Twitter",
  // },
];

const logos = [
  {
    id: 1,
    src: <CSE />,
    alt: "Sparkai",
    width: 163,
  },
  {
    id: 2,
    src: <PromptPrivacy />,
    alt: "CSE",
    width: 83,
  },
  // {
  //   id: 3,
  //   src: <ProgrammersForce />,
  //   alt: "PromptPrivacy",
  //   width: 114,
  // },

  // {
  //   id: 4,
  //   src: <Katia />,
  //   alt: "auralian",
  //   width: 118,
  // },
  {
    id: 5,
    src: <VerPower />,
    alt: "healhonomy",
    width: 456,
  },
  {
    id: 6,
    src: <Auralian />,
    alt: "ethera",
    width: 196,
  },
  {
    id: 7,
    src: <Sparkai />,
    alt: "youtube20",
    width: 244,
  },
  // {
  //   id: 8,
  //   src: "/Assets/logo/logowe.svg",
  //   alt: "programmerForce",
  //   width: 100,
  // },
  {
    id: 9,
    src: <Ethera />,
    alt: "verPower",
    width: 165,
  },
  {
    id: 10,
    src: <Youtube20 />,
    alt: "katia",
    width: 142,
  },
];

const Home = () => {
  return (
    <section id="home">
      <div
        className="p-5 md:p-10 lg:p-16 no-scrollbar pt-28 md:pt-32 lg:pt-32 xl:pt-16"
        
      >
        <div className="flex items-center justify-between mt-2 lg:mt-0">
          <img
            src={profilePic}
            alt="Home Icon"
            width={288}
            height={356}
            className="w-[100px] h-[100px] object-cover object-top xl:w-[120px] xl:h-[120px] 3xl:w-[150px] 3xl:h-[150px] rounded-lg"
          />
          <button className="text-[#00E26A] right-0  py-2 3xl:px-6 3xl:py-4 bg-mygreen/5 hover:bg-mygreen/10 hover:cursor-pointer rounded-[6px] flex gap-x-2 items-center -mt-14 md:-mt-20 px-5">
            <div className="rounded-full bg-mygreen text-base 3xl:text-xl w-2.5 h-2.5" />
            Open to work
          </button>
        </div>
        <div className="lg:max-w-[800px] xl:max-w-full">
          <p className="text-white text-2xl sm:text-4xl 3xl:text-5xl mt-10 sm:leading-[50px] 3xl:leading-[4rem] ">
            Hey, I'm Shahid - an expert in back-end Development - Development Lead at SparkAi
          </p>
          <p className="text-[#808080] mt-5 text-sm sm:text-xl 3xl:text-2xl">
            Experienced entrepreneur with 4+ years in Backend Development,
            Artificial Intelligence, Data Science, and Machine Learning. Skilled
            leader and effective communicator dedicated to maximizing revenue
            and efficiency at Software Technology Services.
          </p>
        </div>
        <div className="flex gap-x-3 mt-10 3xl:mt-20">
          {socialLinks.map((link) => (
            <a
              key={link.id}
              target="blank"
              href={link.href}
              className="py-2 px-2 border border-[#292929] hover:text-white group"
            >
              <div className="group-hover:filter group-hover:invert group-hover:brightness-0">
                <link.Component className="text-current group-hover:text-white" />
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="w-full border-t border-b border-[#292929] overflow-hidden inline-flex flex-nowrap [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-8 animate-infinite-scroll py-12">
          {logos.map(({ id, alt, src, width }) => (
            <li key={id}>
              <div>{src}</div>
            </li>
          ))}
        </ul>
        <ul
          className="flex items-center justify-center md:justify-start [&_li]:mx-8 animate-infinite-scroll py-12"
          aria-hidden="true"
        >
          {logos.map(({ id, alt, src, width }) => (
            <li key={id}>
              <div>{src}</div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Home;
