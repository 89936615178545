import AI from "../SkillsIcons/AiIcon";
import DjangoIcon from "../SkillsIcons/DjangoIcon";
import PostManIcon from "../SkillsIcons/PostManIcon";
import ProblemSolvingIcon from "../SkillsIcons/ProblemSolvingIcon";
import Skill1 from "../SkillsIcons/Python";

const skillsData = [
  {
    id: 1,
    imgSrc: <Skill1 />,
    imgAlt: "Python",
    name: "Python",
    description: "Python & R for analysis",
  },
  {
    id: 2,
    imgSrc: <ProblemSolvingIcon />,
    imgAlt: "Problem Solving",
    name: "Problem Solving",
    description: "Good problem skills",
  },
  {
    id: 3,
    imgSrc: <AI />,
    imgAlt: "AI",
    name: "AI",
    description: "Python & R for analysis",
  },
  {
    id: 4,
    imgSrc: <DjangoIcon />,
    imgAlt: "Django",
    name: "Django",
    description: "Python web framework",
  },
  // {
  //   id: 5,
  //   imgSrc: <DataScienceIcon />,
  //   imgAlt: "Data Science",
  //   name: "Data Science",
  //   description: "Python & R for analysis",
  // },
  // {
  //   id: 6,
  //   imgSrc: <MachineLearningIcon />,
  //   imgAlt: "Machine Learning",
  //   name: "Machine Learning",
  //   description: "Python & R for analysis",
  // },
  {
    id: 7,
    imgSrc: <PostManIcon />,
    imgAlt: "Postman",
    name: "Postman",
    description: "API testing tool",
  },
];

const Skills = () => {
  return (
    <div className="border-t border-[#292929] p-5 md:p-10 lg:p-14" id="skills">
      <p className="text-white text-3xl sm:text-5xl mt-5">Skills</p>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 ">
        {skillsData.map((skill) => (
          <div
            key={skill.id}
            className="mt-8 border p-5 border-[#292929] rounded-lg flex items-center space-x-2 lg:space-x-3"
          >
            <div>{skill.imgSrc}</div>
            <div>
              <p className="text-white font-bold xl:text-xl">{skill.name}</p>
              <p className="text-[#808080]">{skill.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
