import React from "react";
import E1 from "../experienceIcons/E1";
import E2 from "../experienceIcons/E2";
import E3 from "../experienceIcons/E3";
import E4 from "../experienceIcons/E4";
import E5 from "../experienceIcons/E5";

const experienceData = [
  // {
  //   id: 1,
  //   icon: <E1 />,
  //   title: "Director - Cognitive Storage Solution - Prompt Privacy",
  //   description:
  //     "The director of Cognitive Storage Solution is leading the 'Prompt Privacy' project remotely, ensuring data security.",
  // },
  {
    id: 2,
    icon: <E2 />,
    title: "Development Lead at Lahore SparkAi",
    description:
      "The CEO is driving efficient operations with innovation, ensuring impactful leadership and strategic growth.",
  },
  // {
  //   id: 3,
  //   icon: <E3 />,
  //   title: "Data Scientist & programmer Solution - Programmer Force",
  //   description:
  //     "At Programmer Force, from Aug '19 to Dec '20, the data scientist fueled innovation.",
  // },
  // {
  //   id: 4,
  //   icon: <E4 />,
  //   title: "Software Engineer - at Aurelian Solution.",
  //   description:
  //     "At Aurelian Solution, from May '18 to Jul '19, contributed to software development.",
  // },
  // {
  //   id: 5,
  //   icon: <E5 />,
  //   title: "Game Development - Unity Technologies",
  //   description:
  //     "At Unity Technologies, game development was enhanced through contributions to innovative projects.",
  // },
];

const Experience = () => {
  return (
    <div className="p-5 md:p-10 lg:p-14 border-b-[#292929]" id="experience">
      <p className="text-3xl sm:text-5xl text-white mt-3">Experience</p>
      <div className="mt-10 gap-y-10 sm:mt-20 sm:gap-y-20 gap-x-10 grid xl:grid-cols-3">
        {experienceData.map((experience) => (
          <div key={experience.id} className="space-y-3 ">
            <div className="bg-[#292929] p-5 w-[70px] rounded-lg">
              {experience.icon}
            </div>

            <div className="space-y-3 mt-10">
              <p className="text-white text-2xl hover:text-[#FBA310] hover:cursor-pointer mt-10">
                {experience.title}
              </p>
              <p className="text-[#515151]">{experience.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Experience;
