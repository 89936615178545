import { useEffect, useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const initialValues = {
    userFullName: "",
    email: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    const nameRegex = /^[a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!values.userFullName) {
      errors.userFullName = "Username is required";
    } else if (!nameRegex.test(values.userFullName)) {
      errors.userFullName = "This is not a valid Full name";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);

    if (Object.keys(errors).length === 0) {
      const templateParams = {
        user_name: formValues.userFullName,
        user_email: formValues.email,
        message: formValues.message,
      };

      // Send email using EmailJS
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then(
          (result) => {
            console.log("Email sent successfully:", result.text);
            setFormValues(initialValues); // Reset form values
          },
          (error) => {
            console.error("Error sending email:", error.text);
          }
        );

      setIsSubmit(false);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("Form submitted successfully");
    }
  }, [formErrors, isSubmit]);

  return (
    <div className="p-5 md:p-10 lg:p-14 border-t border-[#292929]" id="contact">
      <p className="text-white text-5xl 3xl:6xl">Let's Chat</p>
      <p className="text-[#808080] mt-7 3xl:text-xl">
        If you'd like to talk about a potential project or just say hi, send me
        a message or email me at <br />
        <a href="shahidmuneerawan@gmail.com" className="text-white hover:text-[#FBA310]">
          shahidmuneerawan@gmail.com
        </a>
      </p>
      <form onSubmit={handleSubmit} className="mt-14">
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="flex flex-col 3xl:text-2xl">
            <input
              id="userFullName"
              name="userFullName"
              className="h-10 bg-[#181818] text-white focus:outline-none pl-4 rounded-lg"
              type="text"
              placeholder="Name"
              value={formValues.userFullName}
              onChange={handleChange}
            />
            <p className="text-red-400">{formErrors.userFullName}</p>
          </div>
          <div className="flex flex-col 3xl:text-2xl 2xl:text-xl">
            <input
              id="email"
              name="email"
              className="h-10 bg-[#181818] pl-4 rounded-lg text-white focus:outline-none"
              type="email"
              placeholder="Email"
              value={formValues.email}
              onChange={handleChange}
            />
            <p className="text-red-400">{formErrors.email}</p>
          </div>
        </div>

        <div className="flex flex-col mt-4 3xl:text-2xl 2xl:text-xl">
          <textarea
            id="message"
            name="message"
            className="h-44 bg-[#181818] text-white pl-4 pt-4 focus:outline-none focus:border-rose-600"
            placeholder="Message"
            value={formValues.message}
            onChange={handleChange}
          ></textarea>
          <p className="text-red-400">{formErrors.message}</p>
        </div>

        <button
          type="submit"
          className="w-[100%] text-black font-bold bg-[#FBA310] hover:cursor-pointer hover:bg-[#FBA310]/60 rounded-lg py-3 mt-4 3xl:text-xl"
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default Contact;
